<vdr-action-bar>
  <vdr-ab-left>
    <input 
      type="text" 
      name="emailSearchTerm" 
      [formControl]="searchTerm"
      [placeholder]="'customer.search-customers-by-email-last-name-postal-code' | translate" 
      class="search-input"
    />
  </vdr-ab-left>
</vdr-action-bar>

<vdr-data-table 
  [items]="items$ | async" 
  [itemsPerPage]="itemsPerPage$ | async" 
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async" 
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  
  <vdr-dt-column [expand]="true">{{ 'customer.name' | translate }}</vdr-dt-column>
  <vdr-dt-column [expand]="true">{{ 'customer.email-address' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-customer="item">
    <td class="left align-middle">
      {{ customer.title }} {{ customer.firstName }} {{ customer.lastName }}
    </td>
    <td class="left align-middle">{{ customer.emailAddress }}</td>
    <td class="right align-middle">
      <vdr-table-row-action 
        iconShape="discount" 
        [label]="'order-discount.discount' | translate"
        [linkTo]="['./', customer.id]"
      ></vdr-table-row-action>
    </td>
  </ng-template>
</vdr-data-table>