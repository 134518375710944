import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Dialog,
  ProductVariant,
} from '@vendure/admin-ui/core';

@Component({
  selector: 'discount-group-customer-one-dialog',
  templateUrl: './discount-group-customer-one-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles:[` .form-field{ display: flex; flex-direction: column; } `]
})
export class DiscountGroupCustomerOneDialogComponent implements Dialog<any>, OnInit {
  
  @Input() id: string;
  @Input() collectionId: string;
  @Input() collectionName: string;
  @Input() discountGroupId: string;
  @Input() factor: number;

  get formFactor() { return this.form.get('factor'); }

  resolveWith: (result?: { 
    id: string,
    collectionId: string, 
    discountGroupId: string,
    factor: number,
  }) => void;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      collectionName: ['', Validators.required],
      collectionId: ['', Validators.required],
      factor: [1, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]],
    });

    if (this.collectionId) {
      this.form.patchValue({ 
        collectionName: this.collectionName,
        collectionId: this.collectionId,
        factor: this.factor,
     });
    }
  }

  cancel() {
    this.resolveWith();
  }

  save() {
    this.resolveWith({
      id: this.id,
      collectionId: this.form.get('collectionId')?.value,
      discountGroupId: this.form.get('discountGroupId')?.value,
      factor: this.form.get('factor')?.value,
    });
  }

  collectionSelected(event) {
    const { id, name } = event;

    this.collectionId = id;
    
    this.form.patchValue({
      collectionId: id, 
      collectionName: name,
    }, { emitEvent: true })
  }
}
