<vdr-relation-card
    placeholderIcon="library"
    (remove)="remove()"
    [entity]="discountGroup$ | async"
    [selectLabel]="'discountGroup' | translate"
    [removable]="!config.list"
    [readonly]="readonly"
    [entity]="' '"
>
  <ng-template vdrRelationCardPreview let-discountGroup="entity">
    <div></div>
  </ng-template>
  <ng-template vdrRelationCardDetail let-discountGroup="entity">
    Code: 
    <span *ngIf="discountGroup.code">
      <a [routerLink]="['/extensions/discounts/groups', discountGroup.id]">{{ discountGroup.code }}</a>
    </span>
    <span *ngIf="!discountGroup?.code && defaultValue">
      {{defaultValue}}
    </span>
  </ng-template>
</vdr-relation-card>

<ng-select
  #selector
  [items]="searchResults$ | async"
  [loading]="searchLoading"
  [typeahead]="searchInput$"
  [placeholder]="'search discount group by code' | translate"
  (change)="selectResult($event)"
>
  <ng-template ng-option-tmp let-item="item">
    {{ item.code }}
  </ng-template>
</ng-select>
 