import gql from 'graphql-tag';

export const GET_DISCOUNT_GROUPS = gql`
  query GetDiscountGroups {
    discountGroups {
      items {
        id
        code
      }
    }
  }
`;  


export const GET_DISCOUNT_GROUP_ONE = gql`
  query discountGroup($id: ID!) {
    discountGroup(id: $id) {
      id
      code
    }
  }
`;  