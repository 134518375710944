import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListComponent, DataService, ModalService, AssetPickerDialogComponent } from '@vendure/admin-ui/core';
import { IGroupDiscountCustomerCustomer } from '../../../types';
import { DISCOUNT_GROUP_CUSTOMER_IMPORT_UPLOAD_FILE, GET_DISCOUNT_GROUP_CUSTOMER_IMPORT_LIST } from './dg-customer-import.graphql';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dg-customer-import',
  templateUrl: './dg-customer-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupCustomerImportComponent extends BaseListComponent<any, any, any> implements OnInit {

  private customerId: string;

  uploading = false;

  constructor(
    private dataService: DataService,
    private modalService: ModalService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(router, route);

    this.route.params.subscribe((params) => {
      super.setQueryFn(
        (...args: any[]) => {
          return this.dataService.query<IGroupDiscountCustomerCustomer>(GET_DISCOUNT_GROUP_CUSTOMER_IMPORT_LIST, {
            ...args,
            customerId: params.customerId,
          });
        },
        (data) => data.discountGroupCustomerImport,
      );

      this.customerId = params.customerId;
    }).unsubscribe()
  }

  ngOnInit() {
    super.ngOnInit(); 
  }

  selectFiles(files: File[]) {
    if (!files) {
      return;
    }

    const file = files[0];
  
    console.log('file',file);
    
    this.uploading = true;
    this.dataService.mutate<IGroupDiscountCustomerCustomer>(DISCOUNT_GROUP_CUSTOMER_IMPORT_UPLOAD_FILE, {
      file,
      customerId: this.customerId,
    })
    .pipe(finalize(() => (this.uploading = false)))
    .subscribe((data) => {
      console.log('data', data);
      
    })
  }
}
