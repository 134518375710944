<ng-template vdrDialogTitle>
  <span *ngIf="id">{{ 'update discount group' | translate }}</span>
  <span *ngIf="!id">{{ 'create discount group' | translate }}</span>
</ng-template>

<form [formGroup]="form">
  <vdr-form-field [label]="'Code'" for="code">
    <input 
      id="code"
      type="text" 
      formControlName="code"
    />
  </vdr-form-field>
</form>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button type="submit" (click)="save()" [disabled]="!form.valid" class="btn btn-primary">
    <span *ngIf="id">{{ 'update discount group' | translate }}</span>
    <span *ngIf="!id">{{ 'create discount group' | translate }}</span>
  </button>
</ng-template>