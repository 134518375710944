import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuSection, registerFormInputComponent, addActionBarItem, OnClickContext, addNavMenuItem, registerCustomDetailComponent } from '@vendure/admin-ui/core';
import { MyDiscountGroupSelectorComponent } from './components/discount-groups';
import { EXPORT_ORDERS_TO_PDF, EXPORT_ORDER_TO_PDF } from './graphql';
import { CustomerPasswordComponent } from './components/customer/customer-password/customer-password.component';

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem({
      id: 'shop-products',
      label: 'Products',
      routerLink: ['/extensions/shop/products'],
      icon: 'star',
    }, 'catalog', 'facets'),
    addNavMenuItem({
      id: 'shop-customers',
      label: 'Customers',
      routerLink: ['/extensions/shop/customers'],
      icon: 'star',
    }, 'customers'),
    addNavMenuSection({
      id: 'shop',
      label: 'Shop',
      items: [
        // {
        //   id: 'discounts-individuals',
        //   label: 'Individuals',
        //   routerLink: ['/extensions/discounts/individuals'],
        //   icon: 'star',
        // },
        {
          id: 'discount-groups',
          label: 'Discount Groups',
          routerLink: ['/extensions/shop/discount-groups'],
          icon: 'star',
        },
        {
          id: 'discount',
          label: 'Discounts Customers',
          routerLink: ['/extensions/shop/discounts'],
          icon: 'star',
        },
        {
          id: 'price-lists',
          label: 'Price List Import',
          routerLink: ['/extensions/shop/price-lists'],
          icon: 'star',
        },
      ],
    }, 'settings'),
    addActionBarItem({
      id: 'export-orders-to-pdf',
      label: 'Export orders to PDF',
      locationId: 'order-list',
      onClick: (event, context: OnClickContext) => {     
        context.notificationService.info('Creating PDFs...');    
        context.route.queryParams.subscribe(queryParams => {
          const filter = queryParams?.filter || 'active'; 
      
          context.dataService.query(EXPORT_ORDERS_TO_PDF, { languageCode: 'en', filter })
            .mapSingle(data => data).subscribe(data => {
              //@ts-ignore
              if (data?.exportOrdersToPDF?.url) {
                //@ts-ignore
                window.open(data.exportOrdersToPDF.url, '_target');
              }
            });

        })
      }
    }),
    addActionBarItem({
      id: 'export-order-to-pdf',
      label: 'Export the order to PDF',
      locationId: 'order-detail',
      onClick: (event, context: OnClickContext) => {      
        context.route.params.subscribe(param => {
          const orderId = param.id;

          context.notificationService.info('Creating PDF...');

          context.dataService.query(EXPORT_ORDER_TO_PDF, { orderId, languageCode: 'en' })
            .mapSingle(data => {
              return data;
            }).subscribe(data => {
              //@ts-ignore
              if (data?.exportOrderToPDF?.url) {
                //@ts-ignore
                window.open(data.exportOrderToPDF.url, '_target');
              }
            })
        });
      }
    }),
    registerFormInputComponent('my-discount-group-selector', MyDiscountGroupSelectorComponent),
    registerCustomDetailComponent({
      locationId: 'customer-detail',
      component: CustomerPasswordComponent,
    }),
  ],
  exports: [],
})
export class MainUiExtensionModule { }
