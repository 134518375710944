<form class="form" [formGroup]="form">
  <vdr-form-field [label]="'customer.password' | translate" for="password">
    <input id="password" type="password" formControlName="password" />
  </vdr-form-field>
  <vdr-form-field [label]="'Confirm Password'" for="confirmPassword">
    <input id="confirmPassword" type="password" formControlName="confirmPassword" />
  </vdr-form-field>
  <div>
    <button
      *vdrIfPermissions="'UpdateCustomer'"
      class="btn btn-primary"
      (click)="changePassword()"
      [disabled]="!(form.valid && form.dirty)"
    >
      {{ 'Change password' | translate }}
    </button>
  </div>
</form>
