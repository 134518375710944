import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BaseListComponent, DataService, ModalService } from '@vendure/admin-ui/core';
import { debounceTime, takeUntil, switchMap } from 'rxjs/operators';
import { ID } from '@vendure/core';
import { IGroupDiscountCollection } from '../../../types';
import { GET_DISCOUNT_GROUP_COLLECTION_LIST, MUTATION_DISCOUNT_GROUP_COLLECTION, MUTATION_DISCOUNT_GROUP_COLLECTION_DELETE } from './discount-group-collection-list.graphql';
import { DiscountGroupCollectionDialogComponent } from '../discount-group-collection-dialog/discount-group-collection-dialog.component';

@Component({
  selector: 'discount-group-collection-list',
  templateUrl: './discount-group-collection-list.component.html',
  styles: [`
    .img-collection {
      height: 32px;
      width: 32px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupCollectionListComponent extends BaseListComponent<any, any, any> implements OnInit {
  filterTermControl = new FormControl('');

  discountGroupId: string;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private dataService: DataService,
    private modalService: ModalService
  ) {
    super(router, route);

    this.route.params.subscribe((params) => {
      super.setQueryFn(
        (...args: any[]) => {
          return this.dataService.query<IGroupDiscountCollection>(GET_DISCOUNT_GROUP_COLLECTION_LIST, {
            ...args,
            discountGroupId: params.discountGroupId,
          });
        },
        (data) => data.discountGroupsCollections,
      );

      this.discountGroupId = params.discountGroupId;
    }).unsubscribe()
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filterTermControl.valueChanges
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => this.refresh());
  }

  addDiscount() {
    this.modalService
      .fromComponent(DiscountGroupCollectionDialogComponent)
      .subscribe((data) => {
        if (!data) return;
        
        this.upsertDataDiscount(this.discountGroupId, data.collectionId, data.factor);

      }, err => {});
  }

  editDiscount(item: any) {
    this.modalService
      .fromComponent(DiscountGroupCollectionDialogComponent, {
        locals: {
          collectionName: item.collection.name,
          collectionId: item.collection.id,
          factor: item.factor,
        }
      })
      .subscribe((data) => {
        if (!data) return;
        
        this.upsertDataDiscount(this.discountGroupId, data.collectionId, data.factor);
      
      }, err => {});
  }

  private upsertDataDiscount(discountGroupId: ID, collectionId: ID, factor: number) {
    this.dataService
      .mutate<IGroupDiscountCollection>(MUTATION_DISCOUNT_GROUP_COLLECTION, {
        discountGroupId,
        collectionId,
        factor,
      })
      .subscribe(() => {
        this.refresh();
      })
  }

  deleteDiscount(id: ID) {
    this.dataService
      .mutate<IGroupDiscountCollection>(MUTATION_DISCOUNT_GROUP_COLLECTION_DELETE, {
        id,
      })
      .subscribe(() => {
        this.refresh();
      })
  }

}
