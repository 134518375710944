import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BaseListComponent, DataService, LogicalOperator } from '@vendure/admin-ui/core';
import { debounceTime, takeUntil, filter } from 'rxjs/operators';
import { SortOrder } from '@vendure/common/lib/generated-shop-types';

@Component({
  selector: 'discount-group-customer-list',
  templateUrl: './discount-group-customer-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupCustomerListComponent extends BaseListComponent<any, any, any> implements OnInit {

  searchTerm = new FormControl('');
  constructor(
    private dataService: DataService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(router, route);
    super.setQueryFn(
      (...args: any[]) => this.dataService.customer.getCustomerList(...args).refetchOnChannelChange(),
      data => data.customers,
      (skip, take) => ({
        options: {
          skip,
          take,
          filter: {
            emailAddress: {
              contains: this.searchTerm.value,
            },
            firstName: {
              contains: this.searchTerm.value,
            },
            lastName: {
              contains: this.searchTerm.value,
            },
            postalCode: {
              contains: this.searchTerm.value,
            },
          },
          filterOperator: LogicalOperator.OR,
          sort: {
            createdAt: SortOrder.DESC,
          },
        },
      }),
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchTerm.valueChanges
        .pipe(
            filter(value => 2 < value.length || value.length === 0),
            debounceTime(250),
        )
        .subscribe(() => this.refresh());
  }
}
