import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { BaseDataService, GET_COLLECTION_LIST } from '@vendure/admin-ui/core'
import { CollectionFragment, GetCollectionList } from '@vendure/admin-ui/core/common/generated-types';


/**
 * @description
 * A component for selecting product variants via an autocomplete-style select input.
 *
 * @example
 * ```HTML
 * <vdr-collection-selector
 *   (collectionSelected)="selectResult($event)"></vdr-collection-selector>
 * ```
 *
 * @docsCategory components
 */
@Component({
  selector: 'vdr-collection-selector',
  templateUrl: './collection-selector.component.html',
  styleUrls: ['./collection-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionSelectorComponent implements OnInit {
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<any[]>;
  @Output() collectionSelected = new EventEmitter<CollectionFragment>();

  @ViewChild('autoComplete', { static: true })
  private ngSelect: NgSelectComponent;
  constructor(private baseDataService: BaseDataService) { }

  ngOnInit(): void {
    this.initSearchResults();
  }

  private initSearchResults() {
    const searchItems$ = this.searchInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searchLoading = true)),
      switchMap(term => {
        if (!term) {
          return of([]);
        }
        return this.baseDataService
          .query<GetCollectionList.Query, GetCollectionList.Variables>(
            GET_COLLECTION_LIST,
            {
              options: {
                filter: {
                  name: { contains: term }
                },
                take: 10,
                skip: 0,
              },
            },
          )
          .mapSingle(result => result.collections.items);
      }),
      tap(() => (this.searchLoading = false)),
    );

    const clear$ = this.collectionSelected.pipe(mapTo([]));
    this.searchResults$ = concat(of([]), merge(searchItems$, clear$));
  }

  selectResult(collection?: CollectionFragment) {
    if (collection) {
      this.collectionSelected.emit(collection);
      this.ngSelect.clearModel();
    }
  }
}
