import gql from 'graphql-tag';

export const GET_DISCOUNT_GROUP_CUSTOMER_IMPORT_LIST = gql`
  query discountGroupCustomerImport($customerId: ID!) {
    discountGroupCustomerImport(customerId: $customerId) {
      items {
        id
        factor
        oldFactor
      }
      totalItems
    }
  }
`;


export const DISCOUNT_GROUP_CUSTOMER_IMPORT_UPLOAD_FILE = gql`
  mutation discountGroupCustomerImportUploadFile(
    $customerId: ID! 
    $file: Upload!
  ) {
    discountGroupCustomerImportUploadFile(
      customerId: $customerId
      file: $file
    ) {
      __typename
    }
  }
`;
