import gql from 'graphql-tag';

export const GET_DISCOUNT_GROUP_CUSTOMER_LIST = gql`
  query discountGroupsCustomers(
    $discountGroupId: ID!
    $customerId: ID!
  ) {
    discountGroupsCustomers(
      discountGroupId: $discountGroupId
      customerId: $customerId
    ) {
      items {
        id
        factor
        discountGroup {
          code
        }
        collection {
          id
          name
          featuredAsset {
            preview
          }
        }
      }
      totalItems
    }
  }
`;

export const MUTATION_DISCOUNT_GROUP_CUSTOMER = gql`
  mutation formDiscountGroupsCustomers(
    $id: ID
    $collectionId: ID!
    $discountGroupId: ID!
    $customerId: ID!
    $factor: Float!
  ) {
    formDiscountGroupsCustomers(
      id: $id
      discountGroupId: $discountGroupId
      collectionId: $collectionId
      customerId: $customerId
      factor: $factor
    ) {
      id
    }
  }
`;

export const MUTATION_DISCOUNT_GROUP_CUSTOMER_DELETE = gql`
  mutation deleteDiscountGroupsCustomers($id: ID!) {
    deleteDiscountGroupsCustomers(
      id: $id
    ) {
      __typename
    }
  }
`;