<vdr-action-bar>
  <vdr-ab-left>
    Discount Group Customer
  </vdr-ab-left>

  <vdr-ab-right>
      <vdr-action-bar-items locationId="customer-list"></vdr-action-bar-items>
      <button class="btn btn-primary" *vdrIfPermissions="'CreateCustomer'" (click)="addDiscount()">
        <clr-icon shape="plus"></clr-icon>
        {{ 'add discount' | translate }}
      </button>
  </vdr-ab-right>
</vdr-action-bar>

<vdr-data-table 
  [items]="items$ | async" 
  [itemsPerPage]="itemsPerPage$ | async" 
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async" 
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  
  <vdr-dt-column>{{ 'Discount Group code' | translate }}</vdr-dt-column>
  <vdr-dt-column>{{ 'collection' | translate }}</vdr-dt-column>
  <vdr-dt-column>{{ 'factor' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-row="item">
    <td class="left">
      {{row.discountGroup.code}}
    </td>
    <td class="left align-middle">
      <img [src]="row.collection.featuredAsset | assetPreview: 32" class="img-collection">
      {{ row.collection.name }}
    </td>
    <td class="left">
      {{row.factor}}
    </td>
    <td class="right align-middle">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        (click)="editDiscount(row)"
      >
        <clr-icon shape="edit" class="is-link"></clr-icon>
        {{ 'order-discount.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger btn-sm"
        (click)="deleteDiscount(row.id)"
      > 
        <clr-icon shape="trash" class="is-danger"></clr-icon>
        {{ 'order-discount.delete' | translate }}
      </button>
    </td>
  </ng-template>
</vdr-data-table>