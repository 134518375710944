import gql from 'graphql-tag';

export const GET_DISCOUNT_GROUP_LIST = gql`
  query getDiscountGroupList($searchTerm: String) {
    discountGroups(
      searchTerm: $searchTerm
    ) {
      items {
        id
        code
      }
      totalItems
    }
  }
`;


export const MUTATION_DISCOUNT_GROUP = gql`
  mutation formDiscountGroups(
    $id: ID
    $code: String!
  ) {
    formDiscountGroups(
      id: $id
      code: $code
    ) {
      id
    }
  }
`;

export const MUTATION_DISCOUNT_GROUP_DELETE = gql`
  mutation deleteDiscountGroups($id: ID!) {
    deleteDiscountGroups(
      id: $id
    ) {
      id
    }
  }
`;
