import gql from 'graphql-tag';

export const GET_DISCOUNT_GROUP_COLLECTION_LIST = gql`
  query discountGroupsCollections(
    $discountGroupId: ID!
  ) {
    discountGroupsCollections(discountGroupId: $discountGroupId) {
      items {
        id
        factor
        collection {
          id
          name
          featuredAsset {
            preview
          }
        }
      }
      totalItems
    }
  }
`;

export const MUTATION_DISCOUNT_GROUP_COLLECTION = gql`
  mutation formDiscountGroupsCollections(
    $collectionId: ID!
    $discountGroupId: ID!
    $factor: Float!
  ) {
    formDiscountGroupsCollections(
      discountGroupId: $discountGroupId
      collectionId: $collectionId
      factor: $factor
    ) {
      id
    }
  }
`;

export const MUTATION_DISCOUNT_GROUP_COLLECTION_DELETE = gql`
  mutation deleteDiscountGroupsCollections($id: ID!) {
    deleteDiscountGroupsCollections(
      id: $id
    ) {
      __typename
    }
  }
`;
