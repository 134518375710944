import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dialog, } from '@vendure/admin-ui/core';

@Component({
  selector: 'discount-group-collection-dialog',
  templateUrl: './discount-group-collection-dialog.component.html',
  styleUrls: ['./discount-group-collection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupCollectionDialogComponent implements Dialog<any>, OnInit {
  
  @Input() collectionName: string;
  @Input() collectionId: string;
  @Input() factor: number;

  resolveWith: (result?: { collectionId: string, factor: number }) => void;
  form: FormGroup;

  get formFactor() { return this.form.get('factor')  }

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      collectionName: [''],
      collectionId: ['', Validators.required],
      factor: [1, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]],
    });

    if (this.collectionId) {
      this.form.patchValue({ 
        collectionId: this.collectionId,
        collectionName: this.collectionName,
      });
    }

    if (this.factor) {
      this.form.patchValue({ factor: this.factor });
    }
  }

  cancel() {
    this.resolveWith();
  }

  save() {
    this.resolveWith({
      collectionId: this.form.get('collectionId')?.value,
      factor: this.form.get('factor')?.value,
    });
  }

  collectionSelected(event) {
    const { id, name } = event;

    this.collectionId = id;
    
    this.form.patchValue({
      collectionId: id, 
      collectionName: name,
    }, { emitEvent: true })
  }
}
