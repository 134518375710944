<ng-template vdrDialogTitle>
  <span *ngIf="id">{{ 'update discount group collection' | translate }}</span>
  <span *ngIf="!id">{{ 'create discount group collection' | translate }}</span>
</ng-template>

<form [formGroup]="form">
  <vdr-form-field [label]="'Collection'">
    <div class="select-product">
      <div *ngIf="form.get('collectionName')?.value">
        You selected 
        <br/> {{form.get('collectionName')?.value}}
      </div>
      <vdr-collection-selector
        (collectionSelected)="collectionSelected($event)"
      ></vdr-collection-selector>
    </div>
  </vdr-form-field>
  
  <vdr-form-field [label]="'Factor'" for="factor">  
    <div class="form-field">
      <div>
        <input 
          id="factor"
          type="number" 
          formControlName="factor"
        />
      </div>
      <div *ngIf="formFactor?.invalid && (formFactor?.dirty || formFactor?.touched)">
        <div *ngIf="formFactor.errors?.['required']">Factor is required.</div>
        <div *ngIf="formFactor.errors?.['min']">Factor сan't be less than 0.</div>
        <div *ngIf="formFactor.errors?.['max']">Factor сan't be more than 2.</div>
      </div>
    </div>
  </vdr-form-field>
</form>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button type="submit" (click)="save()" [disabled]="!form.valid" class="btn btn-primary">
    <span *ngIf="collectionId">{{ 'update' | translate }}</span>
    <span *ngIf="!collectionId">{{ 'create' | translate }}</span>
  </button>
</ng-template>