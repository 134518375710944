<ng-select
    #autoComplete
    [items]="searchResults$ | async"
    [addTag]="false"
    [multiple]="false"
    [hideSelected]="true"
    [loading]="searchLoading"
    [typeahead]="searchInput$"
    [appendTo]="'body'"
    [placeholder]="'search by collection name' | translate"
    (change)="selectResult($event)"
>
    <ng-template ng-option-tmp let-item="item">
        <img [src]="item.featuredAsset | assetPreview: 32">
        {{ item.name }}
        <small class="sku">{{ item.slug }}</small>
    </ng-template>
</ng-select>
