import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BaseListComponent, DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { CUSTOMER_CHANGE_PASSWORD } from './customer-password.graphql';
import { ActivatedRoute, Router } from '@angular/router';

const matchPassword: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
  if (!group.get('password') || !group.get('confirmPassword')!.value) {
    return { notSame: true };
  }

  let pass = group.get('password')!.value;
  let confirmPass = group.get('confirmPassword')!.value
  return pass === confirmPass ? null : { notSame: true }
}

@Component({
  selector: 'customers-password',
  templateUrl: './customer-password.component.html',
  styles: [`
    .form {
      margin-bottom: 25px;
    }
  `]
})
export class CustomerPasswordComponent {
  @Input() entity$;
  @Input() detailForm;

  customerId = null;

  form: FormGroup = this.formBuilder.group({      
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required]],
  }, { validators: [matchPassword] });

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService,
  ) {
    this.route.params
      .pipe()
      .subscribe(params => {
        this.customerId = params.id;
      })
  }

  changePassword() {
    const password = this.form.get('password')?.value;
    if (!password) {
      return;
    }

    this.dataService.mutate(CUSTOMER_CHANGE_PASSWORD, {
      id: this.customerId,
      password,
    }).subscribe((res: any) => {
      if (res.adminCustomerPasswordChange) {
        this.notificationService.success('The password had been changed');
        this.form.setValue({
          password: '',
          confirmPassword: '',
        })
      }
    })
  }
}