import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BaseListComponent, DataService, ModalService} from '@vendure/admin-ui/core';
import { debounceTime, filter } from 'rxjs/operators';
import { IGroupDiscountCustomer } from '../../../types';
import { GET_DISCOUNT_GROUP_CUSTOMER_LIST, MUTATION_DISCOUNT_GROUP_CUSTOMER, MUTATION_DISCOUNT_GROUP_CUSTOMER_DELETE } from './discount-group-customer-one-list.graphql';
import { ID } from '@vendure/core';
import { DiscountGroupCustomerOneDialogComponent } from '../discount-group-customer-one-dialog/discount-group-customer-one-dialog.component';

@Component({
  selector: 'discount-group-customer-one-list',
  templateUrl: './discount-group-customer-one-list.component.html',
  styles: [`
    .img-collection {
      height: 32px;
      width: 32px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupCustomerOneListComponent extends BaseListComponent<any, any, any> implements OnInit {

  discountGroupId: string;
  customerId: string;

  searchTerm = new FormControl('');
  constructor(
    private dataService: DataService,
    router: Router,
    route: ActivatedRoute,
    private modalService: ModalService
  ) {
    super(router, route);
    this.route.params.subscribe((params) => {
      super.setQueryFn(
        (...args: any[]) => {
          return this.dataService.query<IGroupDiscountCustomer>(GET_DISCOUNT_GROUP_CUSTOMER_LIST, {
            ...args,
            discountGroupId: params.discountGroupId,
            customerId: params.customerId,
          });
        },
        (data) => data.discountGroupsCustomers,
      );

      this.discountGroupId = params.discountGroupId;
      this.customerId = params.customerId;
    }).unsubscribe();
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchTerm.valueChanges
        .pipe(
            filter(value => 2 < value.length || value.length === 0),
            debounceTime(250),
        )
        .subscribe(() => this.refresh());
  }

  addDiscount() {
    this.modalService
      .fromComponent(DiscountGroupCustomerOneDialogComponent)
      .subscribe((data) => {
        if (!data) return;
        
        this.upsertDataDiscount(data.id, data.collectionId, data.factor);

      }, err => {});
  }

  editDiscount(item: any) {
    this.modalService
      .fromComponent(DiscountGroupCustomerOneDialogComponent, {
        locals: {
          collectionId: item.collection.id,
          collectionName: item.collection.name,
          id: item.id,
          discountGroupId: item.discountGroupId,
          factor: item.factor,
        }
      })
      .subscribe((data) => {
        if (!data) return;
        
        this.upsertDataDiscount(data.id, data.collectionId, data.factor);
      
      }, err => {});
  }

  private upsertDataDiscount(id: ID, collectionId: ID, factor: number) {
    this.dataService
      .mutate<IGroupDiscountCustomer>(MUTATION_DISCOUNT_GROUP_CUSTOMER, {
        id,
        customerId: this.customerId, 
        discountGroupId: this.discountGroupId,
        collectionId,
        factor,
      })
      .subscribe(() => {
        this.refresh();
      })
  }

  deleteDiscount(id: ID) {
    this.dataService
      .mutate<IGroupDiscountCustomer>(MUTATION_DISCOUNT_GROUP_CUSTOMER_DELETE, {
        id,
      })
      .subscribe(() => {
        this.refresh();
      })
  }
}
