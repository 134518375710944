import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Dialog,
  ProductVariant,
} from '@vendure/admin-ui/core';
import { ModalService } from '@vendure/admin-ui/core';

@Component({
  selector: 'discount-group-dialog',
  templateUrl: './discount-group-dialog.component.html',
  styleUrls: ['./discount-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupDialogComponent implements Dialog<any>, OnInit {
  
  @Input() id: string;
  @Input() code: string;

  resolveWith: (result?: { id: string; code: string }) => void;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      code: ['', Validators.required],
    });

    if (this.code) {
      this.form.patchValue({ code: this.code });
    }
  }

  cancel() {
    this.resolveWith();
  }

  save() {
    this.resolveWith({
      id: this.id,
      code: this.form.get('code')?.value,
    });
  }
}
