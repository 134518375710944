import gql from 'graphql-tag';

export const EXPORT_ORDER_TO_PDF = gql`
  query exportOrderToPDF(
    $orderId: ID!
    $languageCode: String!
  ) {
    exportOrderToPDF(
      orderId: $orderId
      languageCode: $languageCode 
    ) {
      url
    }
  }
`;

export const EXPORT_ORDERS_TO_PDF = gql`
  query exportOrdersToPDF(
    $languageCode: String!
    $filter: String
  ) {
    exportOrdersToPDF(
      languageCode: $languageCode
      filter: $filter
    ) {
      url
    }
  }
`;
