import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { notNullOrUndefined } from '@vendure/common/lib/shared-utils';

/**
 * A component for selecting files to upload as new Assets.
 */
@Component({
  selector: 'my-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements OnInit {
  /**
   * CSS selector of the DOM element which will be masked by the file
   * drop zone. Defaults to `body`.
   */
  @Input() accept: string;
  @Input() multiple = false;
  @Input() dropZoneTarget = 'body';
  @Input() uploading = false;
  @Input() disabled = false;
  @Output() selectFiles = new EventEmitter<File[]>();

  @ViewChild('fileInput')
  fileInput: ElementRef;

  dragging = false;
  overDropZone = false;
  dropZoneStyle = {
    'width.px': 0,
    'height.px': 0,
    'top.px': 0,
    'left.px': 0,
  };

  ngOnInit() {
    this.fitDropZoneToTarget();
  }

  @HostListener('document:dragenter')
  onDragEnter() {
    this.dragging = true;
    this.fitDropZoneToTarget();
  }

  // DragEvent is not supported in Safari, see https://github.com/vendure-ecommerce/vendure/pull/284
  @HostListener('document:dragleave', ['$event'])
  onDragLeave(event: any) {
    if (!event.clientX && !event.clientY) {
      this.dragging = false;
    }
  }

  /**
   * Preventing this event is required to make dropping work.
   * See https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API#Define_a_drop_zone
   */
  onDragOver(event: any) {
    event.preventDefault();
  }

  // DragEvent is not supported in Safari, see https://github.com/vendure-ecommerce/vendure/pull/284
  onDrop(event: any) {
    event.preventDefault();
    this.dragging = false;
    this.overDropZone = false;
    const files = Array.from<DataTransferItem>(event.dataTransfer ? event.dataTransfer.items : [])
      .map(i => i.getAsFile())
      .filter(notNullOrUndefined);
    this.selectFiles.emit(files);
    this.fileInput.nativeElement.value = "";
  }

  select(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.selectFiles.emit(Array.from(files));
      this.fileInput.nativeElement.value = "";
    }
  }

  private fitDropZoneToTarget() {
    const target = document.querySelector(this.dropZoneTarget) as HTMLElement;
    if (target) {
      const rect = target.getBoundingClientRect();
      this.dropZoneStyle['width.px'] = rect.width;
      this.dropZoneStyle['height.px'] = rect.height;
      this.dropZoneStyle['top.px'] = rect.top;
      this.dropZoneStyle['left.px'] = rect.left;
    }
  }
}
