<vdr-action-bar>
  <vdr-ab-left>
    Upload new discount from excel file
  </vdr-ab-left>
  <vdr-ab-right>
    <my-file-input
      (selectFiles)="selectFiles($event)"
      [uploading]="uploading"
    ></my-file-input>
  </vdr-ab-right>
</vdr-action-bar>

<vdr-data-table 
  [items]="items$ | async" 
  [itemsPerPage]="itemsPerPage$ | async" 
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async" 
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  
  <vdr-dt-column [expand]="true">{{ 'customer.name' | translate }}</vdr-dt-column>
  <vdr-dt-column [expand]="true">{{ 'customer.email-address' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-customer="item">
    <td class="left align-middle">
    </td>
    <td class="left align-middle">{{ customer | json }}</td>
    <td class="right align-middle">
      <vdr-table-row-action 
        iconShape="discount" 
        [label]="'order-discount.discount' | translate"
        [linkTo]="['./', customer.id]"
      ></vdr-table-row-action>
    </td>
  </ng-template>
</vdr-data-table>