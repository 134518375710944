import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '@vendure/admin-ui/core';
import { GET_DISCOUNT_GROUPS, GET_DISCOUNT_GROUP_ONE } from './my-discountgroup-selector.graphql';
import { debounceTime, distinctUntilChanged, mapTo, switchMap, tap, map, startWith, } from 'rxjs/operators';
import { concat, merge, Observable, of, Subject } from 'rxjs';
import { RelationCustomFieldConfig } from '@vendure/admin-ui/core/common/generated-types'
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'my-discount-group-selector',
  templateUrl: './my-discountgroup-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyDiscountGroupSelectorComponent implements OnInit {

  @Input() readonly = false;
  @Input() formControl: FormControl;
  @Input() config: RelationCustomFieldConfig;
  @Input() defaultValue: string = '';

  @ViewChild('selector', { static: true }) ngSelect: NgSelectComponent;

  discountGroup$;
  selected: string;
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<any[]>;

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.discountGroup$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      map(discountGroup => discountGroup?.id),
      distinctUntilChanged(),
      switchMap(id => {
        if (id) {
          return this.dataService
            .query(GET_DISCOUNT_GROUP_ONE, {
              id
            })
            //@ts-ignore
            .mapStream(data => data.discountGroup || undefined);
        } else {
          return of(undefined);
        }
      }),
    );

    const searchItems$ = this.searchInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searchLoading = true)),
      switchMap(term => {
        if (!term) {
          return of([]);
        }
        return this.dataService
          .query(GET_DISCOUNT_GROUPS)
          //@ts-ignore
          .mapSingle(({ discountGroups }) => discountGroups.items);
      }),
      tap(() => (this.searchLoading = false)),
    );

    //@ts-ignore
    this.searchResults$ = concat(of([]), merge(searchItems$, of([])));
  }

  selectResult(product?) {
    if (product) {
      this.formControl.setValue(product);
      this.formControl.markAsDirty();
      this.ngSelect.clearModel();
    }
  }

  remove() {
    this.formControl.setValue(null);
    this.formControl.markAsDirty();
    this.ngSelect.clearModel();
    this.defaultValue = '';
  }
}