<vdr-action-bar>
  <vdr-ab-left>
    <div>
      Discount Group
    </div>
    <input 
      type="text" 
      name="searchTerm" 
      [formControl]="searchTerm"
      [placeholder]="'Search by discount code'" 
      class="search-input"
    />
  </vdr-ab-left>

  <vdr-ab-right>
      <vdr-action-bar-items locationId="customer-list"></vdr-action-bar-items>
      <button class="btn btn-primary" *vdrIfPermissions="'CreateCustomer'" (click)="addDiscount()">
        <clr-icon shape="plus"></clr-icon>
        {{ 'add discount group' | translate }}
      </button>
  </vdr-ab-right>
</vdr-action-bar>

<vdr-data-table
  [items]="items$ | async"
  [itemsPerPage]="itemsPerPage$ | async"
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async"
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  <vdr-dt-column>Code</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-row="item">
    <td class="left align-middle">{{ row.code }}</td>
    <td class="right align-middle">
      <a
        type="button"
        class="btn btn-primary btn-sm"
        [routerLink]="'/extensions/shop/discount-groups/' +  row.id + '/collections'" 
      >
        {{ 'collections' | translate }}
      </a>

      <a
        type="button"
        class="btn btn-primary btn-sm"
        [routerLink]="'/extensions/shop/discount-groups/' + row.id + '/customers'" 
        style="display: none;"
      >
        {{ 'customers' | translate }}
      </a>

      <button
        type="button"
        class="btn btn-primary btn-sm"
        (click)="editDiscount(row)"
      >
        <clr-icon shape="edit" class="is-link"></clr-icon>
        {{ 'order-discount.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger btn-sm"
        (click)="deleteDiscount(row.id)"
        style="display: none;"
      > 
        <clr-icon shape="trash" class="is-danger"></clr-icon>
        {{ 'order-discount.delete' | translate }}
      </button>
    </td>
  </ng-template>
</vdr-data-table>
