import gql from 'graphql-tag';

export const CUSTOMER_CHANGE_PASSWORD = gql`
  mutation adminCustomerPasswordChange (
    $id: ID!
    $password: String!
  ) {
    adminCustomerPasswordChange (
      id: $id
      password: $password
    ) 
  }
`;
